/* Context Menu */

.context-menu-icon {
  &:before {
    color: $black;
    font: normal normal normal 15px/1 "simple-line-icons";
  }
  &.context-menu-icon-cut {
    &:before {
      content: '\e059';
    }
  }
  &.context-menu-icon-edit {
    &:before {
      content: '\e05f';
    }
  }
  &.context-menu-icon-copy {
    &:before {
      content: '\e040';
    }
  }
  &.context-menu-icon-paste {
    &:before {
      content: '\e085';
    }
  }
  &.context-menu-icon-delete {
    &:before {
      content: '\e054';
    }
  }
  &.context-menu-icon-quit {
    &:before {
      content: '\e082';
    }
  }
}
.context-menu-list {
  box-shadow: none;
  border: 1px solid $border-color;
  .context-menu-item {
    span {
      color: $black;
      font-size: .75rem;
      font-family: $type1;
      font-weight: $font-weight-semibold;
    }
    &.context-menu-hover {
      background: $black;
      span {
        color: $white;
      }
    }
  }
}
