/* Wizard */

.wizard {
    display: block;
    width: 100%;
    overflow: hidden;

    a {
        outline: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        >li {
            display: block;
            padding: 0;
        }
    }

    >.steps {
        .current-info {
            position: absolute;
            left: -999em;
        }

        position: relative;
        display: block;
        width: 100%;

        .number {
            font-size: $default-font-size;
        }

        >ul {
            >li {
                width: 25%;

                @media (max-width: 767px) {
                    width: 50%;
                }

                float: left;
                text-align: center;
            }
        }

        a {
            display: block;
            width: auto;
            margin: 0 0.5em 0.5em;
            padding: 1em 1em;
            text-decoration: none;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            font-size: $default-font-size;
            font-family: $type1;
            font-weight: $font-weight-medium;

            &:hover {
                display: block;
                width: auto;
                margin: 0 0.5em 0.5em;
                padding: 1em;
                text-decoration: none;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
            }

            &:active {
                display: block;
                width: auto;
                margin: 0 0.5em 0.5em;
                padding: 1em;
                text-decoration: none;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
            }
        }

        .disabled {
            a {
                background: color(gray-lightest);
                color: color(gray);
                cursor: default;

                &:hover {
                    background: color(gray-lightest);
                    color: color(gray);
                    cursor: default;
                }

                &:active {
                    background: color(gray-lightest)e;
                    color: color(gray);
                    cursor: default;
                }
            }
        }

        .current {
            a {
                background: theme-color(primary);
                color: color(white);
                cursor: default;

                &:hover {
                    background: theme-color(primary);
                    color: color(white);
                    cursor: default;
                }

                &:active {
                    background: theme-color(primary);
                    color: color(white);
                    cursor: default;
                }
            }
        }

        .done {
            a {
                background: lighten(theme-color(primary), 20%);
                color: color(white);

                &:hover {
                    background: lighten(theme-color(primary), 20%);
                    color: color(white);
                }

                &:active {
                    background: lighten(theme-color(primary), 20%);
                    color: color(white);
                }
            }
        }

        .error {
            a {
                background: theme-color(danger);
                color: #fff;

                &:hover {
                    background: theme-color(danger);
                    color: #fff;
                }

                &:active {
                    background: theme-color(danger);
                    color: #fff;
                }
            }
        }
    }

    >.content {
        >.title {
            position: absolute;
            left: -999em;
        }
        background: $content-bg; 
        display: block;
        margin: 0.5em;
        min-height: 24em;
        overflow: hidden;
        position: relative;
        width: auto;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        >.body {
            float: left;
            position: absolute;
            width: 95%;
            height: 95%;
            padding: 2.5%;
            overflow-y: auto;

            ul {
                list-style: disc;

                >li {
                    display: list-item;
                }
            }

            >iframe {
                border: 0 none;
                width: 100%;
                height: 100%;
            }

            input {
                display: block;
                border: 1px solid $border-color;
            }

            input[type="checkbox"] {
                display: inline-block;
            }

            input.error {
                background: rgb(251, 227, 228);
                border: 1px solid lighten(theme-color(danger), 20%);
                color: theme-color(danger);
            }

            label {
                display: inline-block;
                margin-bottom: 0.5em;
            }

            label.error {
                color: theme-color(danger);
                display: inline-block;
                margin-left: 1.5em;
            }
        }
    }

    >.actions {
        padding-top: 20px;

        >ul {
            >li {
                float: left;
                margin: 0 0.5em;
            }

            display: inline-block;
            text-align: right;
        }

        position: relative;
        display: block;
        text-align: right;
        width: 100%;

        a {
            @extend .btn;
            @extend .btn-primary;
        }

        .disabled {
            a {
                @extend .btn-outline-primary;
            }
        }
    }

    &.vertical {
        >.steps {
            display: inline;
            float: left;
            width: 30%;

            @media (max-width: 767px) {
                width: 42%;
            }

            >ul {
                >li {
                    float: none;
                    width: 100%;
                    text-align: left;
                }
            }
        }

        >.content {
            display: inline;
            float: left;
            margin: 0 2.5% 0.5em 2.5%;
            width: 65%;

            @media (max-width: 767px) {
                width: 52%;
            }
        }

        >.actions {
            display: inline;
            float: right;
            margin: 0 2.5%;
            width: 95%;

            @media (max-width: 767px) {
                margin: 0;
                width: 100%;
            }

            >ul {
                >li {
                    margin: 0 0 0 1em;
                }
            }
        }
    }
}

.tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;

    a {
        outline: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        >li {
            display: block;
            padding: 0;
        }
    }

    >.steps {
        .current-info {
            position: absolute;
            left: -999em;
        }

        position: relative;
        display: block;
        width: 100%;

        >ul {
            position: relative;
            margin: 6px 0 0 0;
            top: 1px;
            z-index: 1;

            >li {
                float: left;
                margin: 5px 2px 0 0;
                padding: 1px;
                -webkit-border-top-left-radius: 5px;
                -webkit-border-top-right-radius: 5px;
                -moz-border-radius-topleft: 5px;
                -moz-border-radius-topright: 5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                &:hover {
                    background: #edecec;
                    border: 1px solid $border-color;
                    padding: 0;
                }

                >a {
                    color: color(gray-light);
                    display: inline-block;
                    border: 0 none;
                    margin: 0;
                    padding: 10px 30px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            >li.current {
                background: color(white);
                border: 1px solid $border-color;
                border-bottom: 0 none;
                padding: 0 0 1px 0;
                margin-top: 0;

                >a {
                    padding: 15px 30px 10px 30px;
                }
            }
        }
    }

    >.content {
        >.title {
            position: absolute;
            left: -999em;
        }

        position: relative;
        display: inline-block;
        width: 100%;
        height: 35em;
        overflow: hidden;
        border-top: 1px solid $border-color;
        padding-top: 20px;

        >.body {
            float: left;
            position: absolute;
            width: 95%;
            height: 95%;
            padding: 2.5%;

            ul {
                list-style: disc;

                >li {
                    display: list-item;
                }
            }
        }
    }
}